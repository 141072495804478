.heading{
    margin-bottom: 3rem;
    text-align: center;
 }
 
 .heading span{
    text-transform: uppercase;
    font-size: 6.5rem;
 }
 

.skills .box-container{
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
   gap: 1.5rem;
   align-items: flex-start;
   margin-top: 3rem;
}

.skills .box-container .box {
   padding: 3rem 2rem;
   border: var(--border-light);
   align-items: center;
}

.skills .box-container .box h3{
   margin: 1rem 0;
   font-size: 1.7rem;
}

.skills .box-container .box img{
   font-size: 3rem;
   height: 15rem;
   width: 100%;
   line-height: 7rem;
   color: var(--white);
   background-color: var(--pink);
   margin-bottom: 0.5rem;
}

.skills .box-container .box h3{
   margin: 1.5rem 0;
   font-size: 2.5rem;
}

.skills .box-container .box p {
   font-size: 2rem;
   text-transform: none;
}
