.home {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap:2rem;
    min-height: 100vh;
 }
 
 .home .image{
    flex:1 1 40rem;
 }
 
 .home .image img{
    height: 50rem;
    padding:2rem;
    border:var(--border-bold);
 }
 
 .home .content{
    flex:1 1 40rem;
 }
 
 h3 {
   font-family: 'Tapestry';
    font-size: 5rem;
    margin-bottom: .5rem;
    color: var(--pink);
 }
 
 .home .content span{
    display: inline-block;
    padding:1rem 2rem;
    font-size: 2.5rem;
    margin:1rem 0;
 }

 .btn{
    display: inline-block;
    margin-top: 1rem;
    cursor: pointer;
    padding:1rem 3rem;
    border:var(--border-light);
    font-size: 2rem;
 }
 
 .btn:hover{
    background-color: var(--black);
    color:var(--white);
 }
 
 
 @media (max-width:450px){
 
    .home .image img{
       height: auto;
       width: 100%;
    }
 
    .heading span{
       font-size: 4rem;
    }
 
 }
 