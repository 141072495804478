@import url('https://fonts.googleapis.com/css?family=Rubrik:wght@300;400;500;600&display=swap');

:root{
   --pink:#FF007F;
   --black:#000;
   --white:#fff;
   --light-bg:#f8e1f4;
   --border-bold:.5rem solid var(--black);
   --border-light:.2rem solid var(--black);
}

*{
   font-family: 'Tapestry', sans-serif;
   margin:0; padding:0;
   box-sizing: border-box;
   outline: none; border:none;
   text-decoration: none;
}

*::selection{
   background-color: var(--black);
   color:var(--pink);
}

*::-webkit-scrollbar{
   height: .5rem;
   width: 1rem;
}

*::-webkit-scrollbar-track{
   background-color: transparent;
}

*::-webkit-scrollbar-thumb{
   background-color: var(--pink);
}

html{
   font-size: 62.5%;
   overflow-x: hidden;
   scroll-behavior: smooth;
}

body{
   overflow: hidden;
   transition: .2s linear !important;
   background-color: var(--light-bg);
   color: var(--black);
}

body.active{
   padding-left: 35rem;
}

.pages {
  text-align: center;
  font-size: 3.5rem;
}

.heading{
  margin-bottom: 3rem;
  text-align: center;
}

.heading span{
  text-transform: uppercase;
  font-size: 6.5rem;
}


.portfolio .box-container{
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
   gap:1.5rem;
   align-items: flex-start;
}

.portfolio .box-container .box img{
   height: 20rem;
   width: 100%;
   object-fit: cover;
}

.portfolio .box-container .box h3{
   margin:1rem 0;
   font-size: 2rem;
   font-weight: normal;
}

.portfolio .box-container:hover .box{
  opacity:1;
}

.portfolio .box-container .box:hover{
  opacity: .2;
}

/* media queries  */

@media (max-width:991px){

   html{
      font-size: 55%;
   }

   body.active{
      padding-left: 0;
   }

}

@media (max-width:450px){

   html{
      font-size: 50%;
   }

   .header.active{
      padding-top: 7rem;
   }

   .home .image img{
      height: auto;
      width: 100%;
   }

   .heading span{
      font-size: 4rem;
   }

   .about .biography .bio h3{
      font-size: 2rem;
   }

}