.heading{
    margin-bottom: 3rem;
    text-align: center;
 }
 


.about .biography p{
    margin:2rem auto;
    max-width: 70rem;
    line-height: 2;
    font-size: 1.8rem;
 }
 
 .about .biography .bio{
    margin: 1rem 0;
    background-color: var(--light-bg);
    color: var(--white);

 }
 
h3 {
    padding:1rem 2rem;
    margin:1rem;
    font-size: 2.5rem;
    font-weight: normal;
    text-transform: none;
    color: var(--pink);
 }
 
 .about .biography .bio p{
    font-weight: lighter;
    font-family: 'Rubrik';
    color: var(--black);
 }
 
 .about .skills{
    margin:3rem 0;
 }
 
 .about .skills .progress{
    margin-top: 1rem;
 }
 
 .about .skills .progress .bar{
    margin:1rem auto;
    max-width: 70rem;
    border:var(--border-bold);
    padding:1rem;
 }
 
 .about .skills .progress .bar h3{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 2rem;
    padding:1rem 2rem;
    background-color: var(--pink);
 }
 
 .about .skills .progress .bar span{
    font-weight: normal;
 }
 
 .about .skills .progress .bar:nth-child(1) h3{
    width: 95%;
 }
 
 .about .skills .progress .bar:nth-child(2) h3{
    width: 80%;
 }
 
 .about .skills .progress .bar:nth-child(3) h3{
    width: 65%;
 }
 
 .about .skills .progress .bar:nth-child(4) h3{
    width: 80%;
 }
 
 .about .edu-exp .row{
    display: flex;
    flex-wrap: wrap;
    gap:1.5rem;
 }
 
 .about .edu-exp .row .box-container{
    flex:1 1 40rem;
 }
 
 .about .edu-exp .row .box-container .title{
    padding:1.5rem;
    font-size: 3rem;
    background-color: var(--pink);
 }
 
 .about .edu-exp .row .box-container .box{
    text-align: left;
    margin:1.5rem 0;
    background-color: var(--light-bg);
    padding:2rem;
 }
 
 .about .edu-exp .row .box-container .box span{
    font-size: 1.5rem;
 }
 
 .about .edu-exp .row .box-container .box h3{
    font-size: 2.5rem;
    font-weight: normal;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
 }
 
 .about .edu-exp .row .box-container .box p{
    line-height: 1.5;
    font-size: 1.7rem;
    line-height: 2;
 }
 