.header {
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 20;
  background-color: var(--black);
  width: 100%;
}

.header .nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  max-width: 1500px;
}

.header .nav-container .logo {
  color: var(--pink);
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 3.5rem;
  margin-left: 20px;
  flex-grow: 1;
  font-family: 'Tapestry';
}

.header .nav-container .menu {
  display: flex;
  text-align: center;
  margin-right: 2rem;
  list-style: none;
}

.header .nav-container .menu .menu-item {
  line-height: 40px;
  margin-right: 1rem;
  font-size: 2rem;
}

.header .nav-container .menu .menu-item .menu-link{
  color: var(--pink);
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
}

.menu-item:after {
  content: '';
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
}

.header .menu .menu-item .active,
.header .menu .menu-item:hover{
  background-color: var(--yellow);
  border-radius: 1px solid var(--yellow);
  transition: all 0.2s ease-out;
}

.follow a{
  font-size: 3rem;
  margin:0 1rem;
  cursor: pointer;
  transition: .6s linear !important;
}

.header .follow a:hover{
  transform: rotate(360deg);
}






